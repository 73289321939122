<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style lang="scss">
@import "components/Lightbox2/style.css";

body {
  nav {
    .navbar-brand {
      font-size: 0.8rem;
    }
  }

  main {
    padding-bottom: 5rem;

    #chatlog-loading-spinner {
      position: fixed;
      top: 5px;
      left: 5px;
      z-index: 9999;
    }

    .vue-lb-container {
      z-index: 9999;
    }

    .date-hr {
      padding-top: 100px !important;
      margin-top: -100px !important;
      pointer-events: none;
    }

    .line {
      position: relative;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      width: 100%;

      &.date {
        &.sticky-date {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 999;
        }

        padding: 5px;
        font-weight: bold;
        background-color: #8cff99;

        a {
          color: #c92300;
        }
      }

      &.gap {
        background-color: #aaa;
        height: 1rem;
      }

      &.notice {
        .message {
          color: #ff1493;
        }
      }

      &.topic {
        .message {
          background: #c00;
          color: #fff;

          a {
            color: #ddd;
          }
        }
      }

      &.system {
        color: #666;
        font-size: 10px;
        padding-top: 5px;
      }

      .user {
        padding-left: 0.5rem;
        font-size: 12px;
        color: #666;
      }

      .time {
        font-size: 12px;
        color: #666;
        float: right;
      }

      .message {
        clear: both;
        padding-left: 1rem;
        font-weight: 500;
        font-size: 14px;
        overflow-wrap: break-word;

        a {
          color: #3f55ff;
        }

        .clickable {
          cursor: pointer;
        }

        .emotionicon {
          margin: 0 8px;
        }

        .lock-blockimg,
        .unlock-blockimg {
          margin-left: 20px;
          cursor: pointer;
        }

        .aa {
          white-space: pre-wrap;
        }

        img.emoji {
          height: 1em;
          width: 1em;
          margin: 0 0.05em 0 0.1em;
          vertical-align: -0.1em;
        }
      }
    }
  }

  .vue-lb-container {
    background-color: rgba(255, 255, 255, 0.8);
  }

  [v-cloak] {
    display: none;
  }
}
</style>
