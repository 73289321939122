var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"touchstart":_vm.nop}},[(_vm.line.user)?_c('div',[_c('div',{staticClass:"time"},[_vm._v("("+_vm._s(_vm.line.time)+")")]),_c('div',{staticClass:"user"},[_vm._v(_vm._s(_vm.line.user))]),(_vm.line.images !== null)?_c('div',[_c('div',{staticClass:"message"},[_c('div',{staticClass:"img-parent",style:({
            width: _vm.line.images.thumbnail.width + 'px',
            height: _vm.line.images.thumbnail.height + 'px',
          })},[(_vm.filterImage)?_c('img',{staticClass:"clickable mosaic",attrs:{"src":_vm.line.images.mosaic.url,"data-line-id":_vm.line.line_id},on:{"click":function($event){return _vm.openGallery(_vm.line.images)},"touchstart":_vm.nop}}):_vm._e(),(_vm.line.images.imageIndex !== undefined)?_c('span',[_c('img',{staticClass:"clickable normal",attrs:{"src":_vm.line.images.thumbnail.url,"data-line-id":_vm.line.line_id},on:{"click":function($event){return _vm.openGallery(_vm.line.images)},"touchstart":_vm.nop}})]):_c('span',[_c('a',{attrs:{"href":_vm.line.images.original.url,"target":"_blank"}},[_c('img',{staticClass:"normal",attrs:{"src":_vm.line.images.thumbnail.url,"data-line-id":_vm.line.line_id},on:{"touchstart":_vm.nop}})])])]),_c('a',{staticClass:"original-image-link",style:({
            top: _vm.line.images.thumbnail.height / 2 - 20 + 'px',
            left: _vm.line.images.thumbnail.width + 50 + 'px',
          }),attrs:{"href":_vm.line.images.original.url,"target":"_blank"}},[_c('i',{staticClass:"fas fa-link"})])])]):(_vm.line.videos !== null)?_c('div',_vm._l((_vm.line.videos),function(video){return _c('video',{key:video.src,attrs:{"controls":"","width":"240","height":"135","poster":"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsaCj6DwAFbQJzZv1PAQAAAABJRU5ErkJggg=="}},[_c('source',{attrs:{"src":video.src,"type":video.type}})])}),0):_c('div',[_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.line.safeText)}})])]):_c('div',[_vm._v(" *** "+_vm._s(_vm.line.time)+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.line.safeText)}}),_vm._v(" *** ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }