<template>
  <div @touchstart="nop">
    <div v-if="line.user">
      <div class="time">({{ line.time }})</div>
      <div class="user">{{ line.user }}</div>
      <div v-if="line.images !== null">
        <div class="message">
          <div
            class="img-parent"
            :style="{
              width: line.images.thumbnail.width + 'px',
              height: line.images.thumbnail.height + 'px',
            }"
          >
            <img
              v-if="filterImage"
              :src="line.images.mosaic.url"
              :data-line-id="line.line_id"
              class="clickable mosaic"
              @click="openGallery(line.images)"
              @touchstart="nop"
            />

            <span v-if="line.images.imageIndex !== undefined">
              <img
                :src="line.images.thumbnail.url"
                :data-line-id="line.line_id"
                class="clickable normal"
                @click="openGallery(line.images)"
                @touchstart="nop"
              />
            </span>
            <span v-else>
              <a :href="line.images.original.url" target="_blank">
                <img
                  :src="line.images.thumbnail.url"
                  :data-line-id="line.line_id"
                  class="normal"
                  @touchstart="nop"
                />
              </a>
            </span>
          </div>

          <a
            :href="line.images.original.url"
            target="_blank"
            :style="{
              top: line.images.thumbnail.height / 2 - 20 + 'px',
              left: line.images.thumbnail.width + 50 + 'px',
            }"
            class="original-image-link"
          >
            <i class="fas fa-link"></i>
          </a>
        </div>
      </div>
      <div v-else-if="line.videos !== null">
        <video
          controls
          width="240"
          height="135"
          poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsaCj6DwAFbQJzZv1PAQAAAABJRU5ErkJggg=="
          v-for="video in line.videos"
          :key="video.src"
        >
          <source :src="video.src" :type="video.type" />
        </video>
      </div>
      <div v-else>
        <div class="message" v-html="line.safeText"></div>
      </div>
    </div>
    <div v-else>
      *** {{ line.time }} <span v-html="line.safeText"></span> ***
    </div>
  </div>
</template>

<script>
export default {
  name: "Message",
  props: ["line", "openGallery"],
  data() {
    return {
      filterImage: localStorage.filterImage === "true",
      lineId: null,
    };
  },
  methods: {
    nop() {},
  },
  created() {
    this.lineId = this.line.line_id;
  },
  updated() {
    if (this.lineId !== this.line.line_id) {
      this.lineId = this.line.line_id;
    }
  },
};
</script>

<style lang="scss" scoped>
$z-index-mosaic-image: 100;
$z-index-normal-image: 90;
$z-index-hover-mosaic-image: 80;
$z-index-hover-normal-image: 110;

.message {
  position: relative;

  div.img-parent {
    position: relative;

    img.mosaic,
    img.normal {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: 0;
    }

    img.mosaic {
      z-index: $z-index-mosaic-image;

      &:hover {
        z-index: $z-index-hover-mosaic-image;
      }
    }

    img.normal {
      z-index: $z-index-normal-image;

      &:hover {
        z-index: $z-index-hover-normal-image;
      }
    }
  }

  .original-image-link {
    position: absolute;
    font-size: 1.5rem;
  }
}
</style>
