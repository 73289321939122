<template>
  <div>
    <b-navbar toggleable="md" type="dark" class="bg-dark" variant="info">
      <div class="container">
        <b-navbar-brand href="/">sdl-fan-jp search</b-navbar-brand>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input
                size="sm"
                class="mr-sm-2"
                type="text"
                placeholder="Search"
                v-model="searchQuery"
              />
              <b-button
                size="sm"
                class="my-2 my-sm-0"
                type="submit"
                @click="search"
                >Search</b-button
              >
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <main role="main" class="container">
      <lightbox
        :images="images"
        ref="lightbox"
        :show-light-box="false"
        :show-thumbs="false"
      >
      </lightbox>

      <div id="chatlog-loading-spinner" v-show="loading">
        <img src="/icon-anim.gif" style="width: 128px; height: 128px" />
      </div>

      <div v-for="(line, lineIndex) in lines" :key="lineIndex">
        <div v-if="line['class'] == 'gap'">
          <!-- empty -->
        </div>
        <div v-else>
          <div v-if="line['class'] == 'date'">
            <hr class="date-hr" />
            <div :class="'line ' + line.class">
              <router-link
                :to="{ path: '/', query: { date: line.safeText } }"
                >{{ line.safeText }}</router-link
              >
            </div>
          </div>
          <div v-else>
            <div :class="'line ' + line.class">
              <message :line="line" :openGallery="openGallery"></message>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox2/Lightbox2";
import Message from "@/components/Message";

const axios = require("axios");

export default {
  name: "Search",
  components: {
    Lightbox,
    Message,
  },
  data() {
    return {
      filterImage: localStorage.filterImage === "true",
      filterImageStates: {},
      loading: false,
      lines: [],
      images: [],
      searchQuery: "",
    };
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let q = this.$route.query.q;

      this.searchQuery = q;

      if (q) {
        this.loading = true;

        const this_ = this;

        axios({
          method: "get",
          url: `/api/search`,
          params: {
            hidesystem: localStorage.hideSystem,
            q: q,
          },
          headers: {
            Authorization: "Bearer " + localStorage.irclogPassword,
          },
        })
          .then(function (response) {
            this_.loading = false;

            this_.lines = response.data.lines;

            // Update images

            this_.images = [];

            for (let line of this_.lines) {
              if (line.images) {
                line.images.imageIndex = this_.images.length;
                this_.images.push({
                  src: line.images.original.url,
                });
              }
            }
          })
          .catch(() => {
            this_.loading = false;

            this_.$router.push("/welcome");
          });
      }
    },
    onMouseenterImage(event) {
      const date = event.target.dataset.date;
      const lineIndex = event.target.dataset.lineIndex;
      this.$set(this.filterImageStates, date + " " + lineIndex, false);
    },
    onMouseleaveImage(event) {
      const date = event.target.dataset.date;
      const lineIndex = event.target.dataset.lineIndex;
      if (this.filterImage) {
        this.$set(this.filterImageStates, date + " " + lineIndex, true);
      }
    },
    swipeHandler(direction, event) {
      if (direction === "right") {
        this.onMouseenterImage(event);
      } else if (direction === "left") {
        this.onMouseleaveImage(event);
      }
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    search() {
      this.$router.push({
        path: "search",
        query: {
          q: this.searchQuery,
        },
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
