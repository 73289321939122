<template>
  <div>
    <b-navbar toggleable="md"
              type="dark"
              class="bg-dark"
              variant="info">
      <div class="container">
        <b-navbar-brand href="/"
                        style="display: flex; align-items: center;">
          sdl-fan-jp

          <img src="/icon-cat-anim.gif"
               style="width: 87px; height: 53px; margin-left: 20px"
               v-show="alwaysNekochan" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-collapse is-nav
                    id="nav_collapse">
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm"
                            class="mr-sm-2"
                            type="text"
                            placeholder="Search"
                            v-model="searchQuery" />
              <b-button size="sm"
                        class="my-2 my-sm-0"
                        type="submit"
                        @click="search">Search</b-button>
            </b-nav-form>

            <b-nav-item-dropdown text="Menu"
                                 right>
              <b-dropdown-item :href="nop"
                               @click="topicSearch">トピック検索</b-dropdown-item>

              <b-dropdown-divider></b-dropdown-divider>

              <div v-if="hideSystem === true">
                <b-dropdown-item :href="nop"
                                 @click="updateHideSystem(false)">Show system messages</b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item :href="nop"
                                 @click="updateHideSystem(true)">Hide system messages</b-dropdown-item>
              </div>

              <b-dropdown-divider></b-dropdown-divider>

              <div v-if="filterImage === true">
                <b-dropdown-item :href="nop"
                                 @click="updateFilterImage(false)">Show images</b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item :href="nop"
                                 @click="updateFilterImage(true)">Hide images</b-dropdown-item>
              </div>

              <b-dropdown-divider></b-dropdown-divider>

              <div v-if="alwaysNekochan === true">
                <b-dropdown-item :href="nop"
                                 @click="updateAlwaysNekochan(false)">Hide rainbow nekochan</b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item :href="nop"
                                 @click="updateAlwaysNekochan(true)">Always show rainbow nekochan</b-dropdown-item>
              </div>

              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item :href="nop"
                               @click="logout">Logout</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>

    <main role="main"
          class="container"
          v-scroll-to-anchor>
      <lightbox :images="images"
                ref="lightbox"
                :show-light-box="false"
                :show-thumbs="false">
      </lightbox>

      <div id="chatlog-loading-spinner"
           v-show="loading">
        <!--
        <img src="/icon-anim.gif"
             style="width: 128px; height: 128px"
             />
           -->
        <img src="/icon-cat-anim.gif"
             style="width: 174px; height: 106px" />
      </div>

      <div v-for="date in dates"
           :key="date"
           class="chatlog-date-block">
        <div v-if="chatlogs[date].next">
          <div v-if="chatlogs[chatlogs[date].next]"></div>
          <div v-else>
            <a class="btn btn-primary btn-lg btn-block"
               :href="nop"
               @click="fetchData(chatlogs[date].next, 'date-top-' + date)">Read {{ chatlogs[date].next }}</a>
          </div>
        </div>

        <hr :id="'date-top-' + date"
            class="date-hr" />

        <div class="line date sticky-date">
          <a :href="nop"
             @click="fetchData(date, 'date-top-' + date)">{{ date }}
            <i class="fas fa-arrow-alt-circle-up"></i>
          </a>
          <a :href="nop"
             @click="scrollTo('date-bottom-' + date)"
             class="date-bottom-link-icon">
            <i class="fas fa-arrow-alt-circle-down"></i>
          </a>
          <span class="float-right">
            <router-link :to="{ path: '/', query: { date: date } }">
              <i class="fas fa-link"></i>
            </router-link>
          </span>
        </div>

        <div v-for="line in chatlogs[date].lines"
             :key="line.line_no">
          <div :class="'line ' + line.class">
            <div v-if="line['class'] == 'gap'">
              <!-- empty -->
            </div>
            <div v-else>
              <message :line="line"
                       :openGallery="openGallery"></message>
            </div>
          </div>
        </div>

        <hr :id="'date-bottom-' + date"
            class="date-hr" />

        <div v-if="chatlogs[date].previous">
          <div v-if="chatlogs[chatlogs[date].previous]"></div>
          <div v-else>
            <a class="btn btn-primary btn-lg btn-block"
               :href="nop"
               @click="fetchData(chatlogs[date].previous)">Read {{ chatlogs[date].previous }}</a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Lightbox from "@/components/Lightbox2/Lightbox2";
import Message from "@/components/Message";

const axios = require("axios");
const VueScrollTo = require("vue-scrollto");

let scrollAnchor = null;

export default {
  name: "Chatlog",
  components: {
    Lightbox,
    Message,
  },
  data() {
    return {
      nop: "javascript:void(0)",
      scrollAnchor: null,
      filterImage: localStorage.filterImage === "true",
      hideSystem: localStorage.hideSystem === "true",
      alwaysNekochan: localStorage.alwaysNekochan === "true",
      loading: false,
      loadingTimer: null,
      previous: null,
      next: null,
      dates: [],
      chatlogs: {},
      images: [],
      searchQuery: "",
    };
  },
  directives: {
    "scroll-to-anchor": {
      // directive definition
      componentUpdated() {
        if (scrollAnchor) {
          const element = document.getElementById(scrollAnchor);
          scrollAnchor = null;
          VueScrollTo.scrollTo(element);
        }
      },
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    reload() {
      this.previous = null;
      this.next = null;
      this.dates = [];
      this.chatlogs = {};
      this.fetchData();
    },
    scrollTo(id) {
      if (id) {
        const element = document.getElementById(id);
        if (element) {
          VueScrollTo.scrollTo(element);
        }
      }
    },
    fetchData(date, scroll) {
      if (date === undefined) {
        date = this.$route.query.date;
        if (date === undefined) {
          date = "today";
        }
      }

      if (scroll === undefined) {
        scroll = this.$route.query.scroll;
      }

      let skipFetch = false;
      if (this.chatlogs[date] && this.chatlogs[date].next) {
        skipFetch = true;
      }

      if (skipFetch) {
        // Skip fetch
        this.scrollTo(scroll);
        return;
      }

      this.loading = true;
      if (this.loadingTimer) {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = null;
      }

      axios({
        method: "get",
        url: "/api/chatlog",
        params: {
          date,
          hidesystem: localStorage.hideSystem,
        },
        headers: {
          Authorization: "Bearer " + localStorage.irclogPassword,
        },
      })
        .then((response) => {
          this.loadingTimer = setTimeout(() => {
            this.loading = false;
          }, 500);

          const date = response.data.date;
          if (this.dates.indexOf(date) === -1) {
            this.dates.push(date);
            this.dates.sort();
            this.dates.reverse();
          }

          this.$set(this.chatlogs, date, response.data);

          // Update images

          this.images = [];

          for (let date of this.dates) {
            for (let line of this.chatlogs[date].lines) {
              if (line.images) {
                if (!/\.pdf$/i.test(line.images.original.url)) {
                  line.images.imageIndex = this.images.length;
                  this.images.push({
                    src: line.images.original.url,
                  });
                }
              }
            }
          }

          scrollAnchor = scroll;
        })
        .catch((error) => {
          this.loading = false;

          if (error.response.status == 401) {
            this.$router.push("/welcome");
          }
        });
    },
    updateFilterImage(value) {
      localStorage.filterImage = value ? "true" : "false";
      this.filterImage = value;
      this.reload();
    },
    updateHideSystem(value) {
      localStorage.hideSystem = value ? "true" : "false";
      this.hideSystem = value;
      this.reload();
    },
    updateAlwaysNekochan(value) {
      localStorage.alwaysNekochan = value ? "true" : "false";
      this.alwaysNekochan = value;
      this.reload();
    },
    openGallery(images) {
      if (images.imageIndex !== undefined) {
        this.$refs.lightbox.showImage(images.imageIndex);
      }
    },
    search() {
      this.$router.push({
        path: "search",
        query: {
          q: this.searchQuery,
        },
      });
      return false;
    },
    topicSearch() {
      this.$router.push({
        path: "search",
        query: {
          q: " TOPIC: ",
        },
      });
    },
    logout() {
      localStorage.irclogPassword = "";
      this.$router.push("/welcome");
    },
  },
};
</script>

<style lang="scss" scoped>
.chatlog-date-block {
  margin-top: 1rem;
}

.date-top-link-icon {
  padding-left: 5px;
  padding-right: 5px;
}

.date-bottom-link-icon {
  padding-left: 5px;
  padding-right: 100px;
}
</style>
