import Vue from 'vue'
import Router from 'vue-router'
import Welcome from '@/components/Welcome'
import Chatlog from '@/components/Chatlog'
import Search from '@/components/Search'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Chatlog',
      component: Chatlog
    },
    {
      path: '/welcome',
      name: 'Welcome',
      component: Welcome
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    }
  ]
})
