<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand" href="/">irclog</a>
      </div>
    </nav>

    <main role="main" class="container" style="margin-top: 1rem">
      <p>パスワードは #sdl-fan-jp のトピックに書いてあります。</p>
      <ul>
        <li>ホスト: irc.libera.chat</li>
        <li>チャンネル名 #sdl-fan-jp</li>
      </ul>

      <form
        class="form-inline text-center"
        method="post"
        v-on:submit.prevent="onSubmit"
      >
        <div class="form-group mx-sm-3 mb-2">
          <label for="password" class="sr-only">Password</label>
          <input
            type="password"
            class="form-control"
            id="password"
            name="password"
            v-model="irclogPassword"
            placeholder="Password"
          />
        </div>
        <button type="submit" class="btn btn-primary mb-2">Login</button>
      </form>
    </main>
  </div>
</template>

<script>
const sha1 = require("js-sha1");

export default {
  name: "Welcome",
  data() {
    return {
      irclogPassword: "",
    };
  },
  methods: {
    onSubmit: function () {
      localStorage.irclogPassword = sha1(this.$data.irclogPassword);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
</style>
